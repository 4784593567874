<template>
  <div class="p-pegas">
    <div
      class="card-table"
      :class="mode ? 'card__tableday' : 'card__tablenight'"
    >
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div
                :class="mode ? 'content__titleday' : 'content__titlenight'"
                class="content-title d-flex align-center mr-2"
              >
                {{ $t("message.users") }}
              </div>
              <div class="block-sarche">
                <div class="header__search">
                  <el-input
                    :placeholder="$t('message.find')"
                    prefix-icon="el-icon-search"
                    :class="mode ? 'input__day' : 'input__night'"
                    v-model="filterForm.search"
                    size="small"
                    round
                    style="width: 100% !important"
                  ></el-input>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="flex-style text-right">
            <crm-create-and-column-settings
              :permission="'users.create'"
              @c-create="drawerCreate = true"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              :columns="columns"
              @c-change="updateColumn"
              :v_can_create="'users.create'"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->
      <div class="card-table-header table-crm-smart">
        <table
          class="table-my-code table-bordered"
          v-loading="loadingData"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.name.show">
                {{ columns.name.title }}
              </th>
              <th v-if="columns.last_name.show">
                {{ columns.last_name.title }}
              </th>
              <th v-if="columns.middle_name.show">
                {{ columns.middle_name.title }}
              </th>
              <th v-if="columns.date_of_birth.show">
                {{ columns.date_of_birth.title }}
              </th>
              <th v-if="columns.gender_id.show">
                {{ columns.gender_id.title }}
              </th>
              <th v-if="columns.phone_number.show">
                {{ columns.phone_number.title }}
              </th>
              <th v-if="columns.email.show">
                {{ columns.email.title }}
              </th>
              <th v-if="columns.status.show">
                {{ columns.status.title }}
              </th>
              <th v-if="columns.user_role_id.show">
                {{ columns.user_role_id.title }}
              </th>
              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>
              <th v-if="columns.updated_at.show">
                {{ columns.updated_at.title }}
              </th>
              <th v-if="columns.settings.show">
                {{ columns.settings.title }}
              </th>
            </tr>
            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <crm-input
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :type="'text'"
                  :className="'id_input'"
                ></crm-input>
              </th>
              <th v-if="columns.name.show">
                <crm-input
                  v-model="filterForm.name"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.name.title"
                  :type="'text'"
                ></crm-input>
              </th>
              <th v-if="columns.last_name.show">
                <crm-input
                  v-model="filterForm.last_name"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.last_name.title"
                  :type="'text'"
                ></crm-input>
              </th>
              <th v-if="columns.middle_name.show">
                <crm-input
                  v-model="filterForm.middle_name"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.middle_name.title"
                  :type="'text'"
                ></crm-input>
              </th>
              <th v-if="columns.date_of_birth.show">
                <crm-date-picker
                  :placeholder="columns.date_of_birth.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.date_of_birth"
                ></crm-date-picker>
              </th>
              <th v-if="columns.gender_id.show">
                <select-gender
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.gender_id"
                ></select-gender>
              </th>
              <th v-if="columns.phone_number.show">
                <crm-input
                  v-model="filterForm.phone_number"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.phone_number.title"
                  :type="'tel'"
                ></crm-input>
              </th>
              <th v-if="columns.email.show">
                <crm-input
                  v-model="filterForm.email"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :placeholder="columns.email.title"
                  :type="'text'"
                ></crm-input>
              </th>
              <th v-if="columns.status.show">
                <select-user-status
                  :placeholder="columns.status.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.status_id"
                  :id="filterForm.status_id"
                  :table_name="'users'"
                ></select-user-status>
              </th>
              <th v-if="columns.user_role_id.show">
                <select-role
                  :placeholder="columns.user_role_id.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.user_role_id"
                ></select-role>
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>
              <th v-if="columns.updated_at.show">
                <crm-date-picker
                  :placeholder="columns.updated_at.title"
                  v-model="filterForm.updated_at"
                  :class="mode ? 'filter__day' : 'filter__night'"
                ></crm-date-picker>
              </th>
              <th class="settinW" v-if="columns.settings.show"></th>
            </tr>
          </thead>
          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="(user, index) in list"
              :key="index + 'users'"
              class="cursor-pointer"
            >
              <td class="w50p" v-if="columns.id.show">
                {{ user.id }}
              </td>
              <td v-if="columns.name.show">
                {{ user.name }}
              </td>
              <td v-if="columns.last_name.show">
                {{ user.last_name }}
              </td>
              <td v-if="columns.middle_name.show">
                {{ user.middle_name }}
              </td>
              <td v-if="columns.date_of_birth.show">
                {{ user.date_of_birth }}
              </td>
              <td v-if="columns.gender_id.show">
                {{ user.gender ? user.gender.name : null }}
              </td>
              <td v-if="columns.phone_number.show">
                {{ user.phone_number }}
              </td>
              <td v-if="columns.email.show">
                {{ user.email }}
              </td>
              <td v-if="columns.status.show">
                {{ user.status ? user.status.name : "" }}
              </td>
              <td v-if="columns.user_role_id.show">
                {{
                  user.user_role
                    ? user.user_role.name
                    : $t("message.role_is_not_set")
                }}
              </td>
              <td v-if="columns.created_at.show">
                {{ user.created_at }}
              </td>
              <td v-if="columns.updated_at.show">
                {{ user.updated_at }}
              </td>
              <td v-if="columns.settings.show" class="settings-td">
                <crm-settings
                  :name="$options.name"
                  :model="user"
                  :actions="actions"
                  :permissionShow="'users.update'"
                  :permissionDestroy="'users.destroy'"
                  :permissionAssignRole="'users.assignRole'"
                  @edit="edit"
                  @delete="destroy"
                  @assignRole="assignRole"
                >
                </crm-settings>
              </td>
            </tr>
          </transition-group>
        </table>
        <crm-pagination
          @c-change="updatePagination"
          :class="mode ? 'pagination__day' : 'pagination__night'"
          :pagination="pagination"
        ></crm-pagination>
      </div>

      <div class="app-modal app-modal__full">
        <el-drawer
          :with-header="false"
          :wrapperClosable="false"
          :visible.sync="drawerCreate"
          size="70%"
          ref="drawerCreate"
          @opened="drawerOpened('drawerCreateChild')"
          @closed="drawerClosed('drawerCreateChild')"
        >
          <crm-create
            ref="drawerCreateChild"
            drawer="drawerCreate"
          ></crm-create>
        </el-drawer>
        <el-drawer
          :with-header="false"
          :wrapperClosable="false"
          :visible.sync="drawerUpdate"
          size="70%"
          ref="drawerUpdate"
          @opened="drawerOpened('drawerUpdateChild')"
          @closed="drawerClosed('drawerUpdateChild')"
        >
          <crm-update
            :selectedItem="selectedItem"
            ref="drawerUpdateChild"
            drawer="drawerUpdate"
          ></crm-update>
        </el-drawer>
        <el-drawer
          :with-header="false"
          :wrapperClosable="false"
          :visible.sync="drawerAssignRole"
          size="70%"
          ref="drawerAssignRole"
          @opened="drawerOpened('drawerAssignRoleChild')"
          @closed="drawerClosed('drawerAssignRoleChild')"
        >
          <crm-assign-role
            :selectedItem="selectedItem"
            ref="drawerAssignRoleChild"
            drawer="drawerAssignRole"
          ></crm-assign-role>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import selectUserStatus from "@/components/filters/inventory/select-status";
import selectGender from "@/components/filters/inventory/select-gender";
import selectRole from "@/components/filters/inventory/select-role";
import { mapGetters, mapActions, mapState } from "vuex";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import CrmAssignRole from "./components/crm-assign-role";
export default {
  name: "UserController",
  mixins: [list],
  components: {
    CrmCreate,
    CrmUpdate,
    selectUserStatus,
    selectGender,
    selectRole,
    CrmAssignRole
  },
  data() {
    return {
      drawerAssignRole: false
    };
  },
  computed: {
    ...mapGetters({
      roles: "roles/list",
      list: "users/list",
      columns: "users/columns",
      pagination: "users/pagination",
      statues: "users/statues",
      filter: "users/filter",
      sort: "users/sort",
      mode: "MODE"
    }),
    actions: function() {
      return ["edit", "delete", "assign_role"];
    }
  },
  methods: {
    ...mapActions({
      loadRoles: "roles/index",
      updateList: "users/index",
      setSearch: "users/setSearch",
      setPagination: "users/setPagination",
      updateSort: "users/updateSort",
      updateFilter: "users/updateFilter",
      updateColumn: "users/updateColumn",
      updatePagination: "users/updatePagination",
      show: "users/show",
      empty: "users/empty",
      delete: "users/destroy",
      refreshData: "users/refreshData"
    }),
    assignRole(model) {
      this.selectedItem = model;
      this.drawerAssignRole = true;
    }
  }
};
</script>
