<template>
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{
                            $t("message.new_m", {
                                m: $t("message.user"),
                            })
                        }}</p>
          <crm-store-update-close :permission="$options.name" :button_type="'store'"  :loading="loadingButton" @c-submit="submit(true)" @c-close="close()"></crm-store-update-close>
        </div>
      </div>      
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4" v-loading="loadingData" :class="mode ? 'table__myday' : 'table__mynight'">
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <el-row :gutter="20">
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t("message.nameFull")}}</span>
                  <el-form-item >
                    <el-input placeholder="Имя" v-model="form.name" size="medium" :class="mode ? 'input__day' : 'input__night'"></el-input>
                  </el-form-item>
                </div>                              
              </el-col>
              <el-col :span="12">
                <div class="app-form__group mb-4">
                  <span class="input--label d-block mb-2" :class="mode ? 'text__day2' : 'text__night2'">{{$t("message.role")}}</span>
                  <el-form-item  prop="user_role_id">
                    <select-role :id="form.user_role_id" v-model="form.user_role_id"></select-role>
                  </el-form-item>
                </div>
              </el-col>             
            </el-row>
          </el-form>
        </div>
      </div>    
    </div>
  </div>
</template>
<script>
  import {mapGetters,mapActions} from "vuex";
  import drawer from "@/utils/mixins/drawer";
  import form from "@/utils/mixins/form";
  import show from "@/utils/mixins/show";
  import selectGender from "@/components/filters/inventory/select-gender";
  import selectUserStatus from "@/components/filters/selects/select-user-status";
  import selectRole from "@/components/filters/inventory/select-role";
  export default {
    mixins: [drawer, form,show],
    name: "UserController",     
    components: {
      selectGender,
      selectUserStatus,
      selectRole       
    },
    computed: {
      ...mapGetters({
        rules: "users/rules",
        model: "users/model",
        columns: "users/columns",
        mode:"MODE"
      }),
    },
    methods: {
      ...mapActions({
        assignRole: "users/assignRole",
        show: "users/show",
      }),
      submit(close = true) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.loadingButton = true;
            this.form.user_id=this.form.id
            this.assignRole(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.$alert(res);
                this.parent().listChanged(); 
                if(close) this.close()                           
              })
              .catch((err) => {                  
                this.loadingButton = false;
                this.$alert(err);
              });
          }
        });
      }      
    },
  };

</script>
